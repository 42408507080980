import LxtButton from "../buttons/lxtButton";

export default function CommingSoon() {
  return (
    <>
      <div className="h-screen w-full flex items-center justify-center">
        <LxtButton color={"red"} text={"Comming Soon!"} />
      </div>
    </>
  );
}
